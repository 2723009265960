export const performanceContent = {
    
    title2:{
        EN:'Introduction',
        繁:'表演者介紹',
        簡:'表演者介绍',
    },
    titleL:{
        EN:'Sound @ Kuk Po',
        繁:'聲@谷埔',
        簡:'声@谷埔',
    },
    titleR:{
        EN:'Performance',
        繁:'劇場',
        簡:'剧场',
    },
    post1Title:{
        EN:'Performance into the Wild – Flowing with Nature',
        繁:'原野劇場 —《與自然流動》',
        簡:'原野剧场 —《与自然流动》',
    },
    post1Text:{
        EN:[`Using music, body movements, and art to bring out the four elements of nature: wind, fire, water, and earth, participants will connect with the natural space through music, body, and art to perceive and record their present state. The artistic process will express the rhythm and state after music and movement with 'a single line,' simultaneously connecting with the mountain contours of nature, gradually recording on paper, and presenting the physical and mental state of connection with nature at the moment.`],
        繁:['以音樂、身體舞動及藝術帶出大自然的風火水土四元素，參加者會透過音樂身體及藝術連結大自然的空間，從而覺察及記錄當下的狀態。藝術的過程將會以「一條線」表達音樂及運動後的節奏及狀態，也同步連結大自然的山形輪廓，慢慢地在畫紙上記錄，並呈現當下與大自然連結的身心狀態。'],
        簡:['以音乐、身体舞动及艺术带出大自然的风火水土四元素，参加者会透过音乐身体及艺术连结大自然的空间，从而觉察及记录当下的状态。艺术的过程将会以「一条线」表达音乐及运动后的节奏及状态，也同步连结大自然的山形轮廓，慢慢地在画纸上记录，并呈现当下与大自然连结的身心状态。'],
    },
    post1Date1:{
        EN:'18/1(Sat), 19/1(Sat)',
        繁:'18/1(六)、19/1(日)',
        簡:'18/1(六)、19/1(日)',
    },
    post1Time1:{
        EN:'2:00 pm – 4:00 pm (2 hours)',
        繁:'下午2:00 – 4:00 (2小時)',
        簡:'下午2:00 – 4:00 (2小时)',
    },
    post1Loca1:{
        EN:'Grassland outside LEE’s Mansion, Tin Sum',
        繁:'田心李氏大宅外草地',
        簡:'田心李氏大宅外草地',
    },
    // post2
    post2Title:{
        EN:'Performance into the Wild – The Seed of Music',
        繁:'原野劇場——《音樂的種子》',
        簡:'原野剧场——《音乐的种子》',
    },
    post2Text:{
        EN:'Through acoustic performances, scenery, culture, and history of Kuk Po are presented, embracing the stories and wisdom of Kuk Po and the countryside of Hong Kong.',
        繁:'透過原聲音樂表演呈現谷埔的風景、文化和歷史，擁抱谷埔和香港鄉郊的故事和智慧。',
        簡:'透过原声音乐表演呈现谷埔的风景、文化和历史，拥抱谷埔和香港乡郊的故事和智慧。',
    },
    post2Date:{
        EN:'25/1(Sat), 26/1(Sun)',
        繁:'25/1(六)、26/1(日)',
        簡:'25/1(六)、26/1(日)',
    },
    post2Time:{
        EN:'2:00 pm – 3:30 pm (1.5 hours)',
        繁:'下午2:00 - 3:30 (1.5小時)',
        簡:'下午2:00 - 3:30 (1.5小时)',
    },
    post2Loca:{
        EN:'Grassland outside LEE’s Mansion, Tin Sum',
        繁:'田心李氏大宅外草地',
        簡:'田心李氏大宅外草地',
    },
    // post3
    post3Title:{
        EN:'YT',
        繁:'薛聿廷YT',
        簡:'薛聿廷YT',
    },
    post3Text:{
        EN:'Since 2014, YT has started the journey of Waldorf early childhood education program and various types of anthroposophical art courses. That same year, YT began conducting workshops with different organizations and elementary schools, focusing on movement and art education for holistic development. In 2019, he completed a three-year, 720-hour Bothmer® Movement course, certified by Bothmer® Movement International in the UK. Bothmerprogram is based on the philosophy of Anthroposophy which was founded by the Austrian educator Rudolf Steiner, aiming to support children and young people in achieving balanced development in body, mind, and spirit.',
        繁:'聿廷從2014年開始就讀華德福幼師課程及不同類型的人智學藝術課程，同年開始與不同機構及小學舉辦工作坊，開展以身心靈發展的運動及藝術教育。2019年完成了三年制共720小時由英國Bothmer® Movement International 頒發的 Bothmer® Movement 空間運動課程，理念源於由奧地利哲學及教育家 Rudolf Steiner 的人智學理念為主，從而幫助兒童及年青人在身心靈三方面平衡發展。',
        簡:'聿廷从2014年开始就读华德福幼师课程及不同类型的人智学艺术课程，同年开始与不同机构及小学举办工作坊，开展以身心灵发展的运动及艺术教育。2019年完成了三年制共720小时由英国Bothmer® Movement International 颁发的 Bothmer® Movement 空间运动课程，理念源于由奥地利哲学及教育家 Rudolf Steiner 的人智学理念为主，从而帮助儿童及年青人在身心灵三方面平衡发展。',
    },
    // post4
    post4Title:{
        EN:'Dolphin',
        繁:'葉靜璇 Dolphin',
        簡:'叶静璇 Dolphin',
    },
    post4Text:{
        EN:'Dolphin is a dedicated community artist who has navigated significant challenges over the years, including inborn hip dislocation, postpartum depression, and challenging relationships. Through mindfulness and artistic exploration, she uncovered the psychological and spiritual roots of her physical challenges, ultimately discovering the keys for transformative self-healing. After a decade in the commercial sector, Dolphin embraced her true passion as a conscious living advocate. She specializes in guiding individuals through self-exploration using diverse artistic mediums and deep listening. As the first SoulCollage Trainer in Cantonese, she regularly leads facilitator training programs in Hong Kong. Over the past seven years, Dolphin has organized more than 500 one-on-one art exploration sessions, as well as ongoing groups and workshops. Her work empowers others to explore their inner landscapes, reclaim their authenticity, and experience newfound freedom.',
        繁:`社區藝術家Dolphin 先後經歷先天性腳患困擾、產後抑鬱、離婚等低潮期，直至透過靜觀與藝術探索，察覺到疾病原來有心因，也找到幫助自己穿越情緒風暴的鎖匙。Dolphin 曾經在職場上馳騁十年，最終找到讓自己真正心動的方向，專注於成人生命教育。Dolphin 擅長透過不同的藝術媒材及深度聆聽，陪伴大家進行自我探索，也是首位心靈拼貼® 粵語培訓講師，定期在香港舉辦廣東話指導員培訓。過去7年，Dolphin 於社區舉辦了超過500場不同形式與主題一對一藝術探索、持續性小組 與工作坊，陪伴大家探索心靈的世界，回歸真實與自由。`,
        簡:'社区艺术家Dolphin 先后经历先天性脚患困扰、产后抑鬱、离婚等低潮期，直至透过静观与艺术探索，察觉到疾病原来有心因，也找到帮助自己穿越情绪风暴的锁匙。Dolphin 曾经在职场上驰骋十年，最终找到让自己真正心动的方向，专注于成人生命教育。Dolphin 擅长透过不同的艺术媒材及深度聆听，陪伴大家进行自我探索，也是首位心灵拼贴® x`粤语培训讲师，定期在香港举办广东话指导员培训。过去7年，Dolphin 于社区举办了超过500场不同形式与主题一对一艺术探索、持续性小组 与工作坊，陪伴大家探索心灵的世界，回归真实与自由。',
    },
    // post5
    post5Title:{
        EN:'Olivene Adi',
        繁:'Olivene Adi',
        簡:'Olivene Adi',
    },
    post5Text:{
        EN:'Olivene Adi has been sharing the beauty of GYROKINESIS®️ movement since 2019, guiding people to find wholeness and healing in their movement. She later founded YOURS, through holding space and various forms of creation, for example, workshops with the themes "SPIRAL TO THE COSMOS" and "DISSOLVE IN THE WAVE," allowing participants to explore the connection between the body, consciousness, and space. Such experiences enable participants to rediscover the essentials and possibilities of life, construct their desired way of living and enjoy the freedom and infinity between each breath.',
        繁:'Olivene Adi由2019年開始透過與大家分享嬋柔運動的美好，從脊椎律動中找到平衡與療癒。\r\n\r\n其後成立YOURS，透過提供空間和以不同形式的創作，讓參加者親身細味生活的品質和可能性。曾分別以主題「Spiral To The Cosmos」、Dissolve in the Wave」爲中軸的多個工作坊，透過身體律動認識身體內外與意識、空間的關係，讓參加者體現生命的美麗，建構自己喜愛的人生，享受生命一呼一吸間那微妙又自由自在的無限空間。',
        簡:'Olivene Adi由2019年开始透过与大家分享婵柔运动的美好，从嵴椎律动中找到平衡与疗癒。\r\n\r\n其后成立YOURS，透过提供空间和以不同形式的创作，让参加者亲身细味生活的品质和可能性。曾分别以主题「Spiral To The Cosmos」、Dissolve in the Wave」爲中轴的多个工作坊，透过身体律动认识身体内外与意识、空间的关係，让参加者体现生命的美丽，建构自己喜爱的人生，享受生命一呼一吸间那微妙又自由自在的无限空间。',
    },
    // post6
    post6Title:{
        EN:'Kawa',
        繁:'黃嘉華Kawa',
        簡:'黄嘉华Kawa',
    },
    post6Text:{
        EN:`Kawa's music blends meditation and spiritual elements with genuine instrument sounds, contemporary worldbeat rhythms, and his own intuition, all woven together through present-moment connections. This fusion creates a unique genre of conscious music that sets a serene, tranquil ambience and invites listeners to embark on a profound introspective journey into their inner selves. Kawa is an artist of Artist-In-Residence in Kadoorie Farm Botanic Garden (KFBG), hosting music programs to bring live instruments performance into nature. He curated a mindful live instrument concert in KFBG & Arch Festival 2021. He also holds instrument jamming workshops or instruments teaching for public audiences and private groups in Museek and other places.`,
        繁:'Kawa是一位多樂器演奏者(班蘇里笛、框鼓、口弦、Rav、人聲等等)。他的音樂融合了冥想和靈性元素、原聲音樂和當代節拍，通過他的直覺和當下的連結而交織在一起。這種融合創造了一種獨特的意識音樂，讓聽眾放低思維頭腦，進入內在的旅程。在城鄉和大自然之間探索更滋養身心靈的生活。2022年開始在南涌半山推行生活體驗、退修、音樂營等，讓人暫時抽離都市的繁忙重新尋回自然、寧靜、自在的狀態，相信簡單直接由心的音樂最療癒動人。Kawa現為嘉道里農場暨植物園駐園表演藝術家，主持多場「循聲」森林音樂會系列，將原聲音樂帶入自然環境。他曾為Arch Festival 2021舉辦靜心音樂會。現恆常於尋樂冷門樂器店舉辦音樂教學和工作坊，包括各種瑜珈/聲療/禪舞班，及與機構合作演奏現場原聲音樂。',
        簡:'Kawa是一位多乐器演奏者(班苏里笛、框鼓、口弦、Rav、人声等等)。他的音乐融合了冥想和灵性元素、原声音乐和当代节拍，通过他的直觉和当下的连结而交织在一起。这种融合创造了一种独特的意识音乐，让听众放低思维头脑，进入内在的旅程。在城乡和大自然之间探索更滋养身心灵的生活。2022年开始在南涌半山推行生活体验、退修、音乐营等，让人暂时抽离都市的繁忙重新寻回自然、宁静、自在的状态，相信简单直接由心的音乐最疗癒动人。Kawa现为嘉道里农场暨植物园驻园表演艺术家，主持多场「循声」森林音乐会系列，将原声音乐带入自然环境。他曾为Arch Festival 2021举办静心音乐会。现恆常于寻乐冷门乐器店举办音乐教学和工作坊，包括各种瑜珈/声疗/禅舞班，及与机构合作演奏现场原声音乐。',
    },
    // post7
    post7Title:{
        EN:'When Jam Band',
        繁:'When Jam Band',
        簡:'When Jam Band',
    },
    post7Text:{
        EN:`When Jam Band is a versatile acoustic collective that captivates audiences with its eclectic, free-spirited sound, blending elements of soft pop, folk, and even acoustic-tinged J-rock, their live performances feature enchanting combinations of vocals, guitar, keyboards, cello, percussion, and analog effects. Driven by a genuine passion for musical exploration, the band aims to craft emotive, immersive experiences that forge a deep connection with listeners.`,
        繁:'When Jam Band是一隊多才多藝的原音樂隊，以其不拘一格、自由奔放的音樂表演深受觀眾喜愛。樂隊的音樂風格融合了soft pop、民謠和帶有原聲色彩的 J-rock 元素，演奏樂器包括人聲、吉他、鋼琴、大提琴、打擊樂器及 MIDI 音效。在對音樂探索的熱情驅動下，樂團致力於打造情感豐富、身臨其境的體驗，與聽眾建立深厚的聯繫。',
        簡:'When Jam Band是一队多才多艺的原音乐队，以其不拘一格、自由奔放的音乐表演深受观众喜爱。乐队的音乐风格融合了soft pop、民谣和带有原声色彩的 J-rock 元素，演奏乐器包括人声、吉他、钢琴、大提琴、打击乐器及 MIDI 音效。在对音乐探索的热情驱动下，乐团致力于打造情感丰富、身临其境的体验，与听众建立深厚的联繫。',
    },
}