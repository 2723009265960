import logo from './logo.svg';
import './App.css';
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom'
import {useRef, useEffect, useState } from 'react';
//
import Home from './screens/Home'
import About from './screens/About'
import Carpark from './screens/Carpark'
import Acknowledgement from './screens/Acknowledgement'
import PageNotFound from './screens/PageNotFound'
//event
import EventHighlights from './screens/EventHighlights'
import Concert from './screens/Concert'
import Performance from './screens/Performance'
import PhotoEx from './screens/PhotoEx'
import PerPride from './screens/PerPride'
import Market from './screens/Market'
import ArtInstallations from './screens/ArtInstallations'
//village experience
import VillageEx from './screens/VillageEx'
import Drinking from './screens/Drinking'
import ScreeningSes from './screens/ScreeningSes'
import SimpleBall from './screens/SimpleBall'
import ArtKukPo from './screens/ArtKukPo'
import VillageEx5 from './screens/VillageEx5'
import VillageEx6 from './screens/VillageEx6'
import VillageEx7 from './screens/VillageEx7'
import VillageEx8 from './screens/VillageEx8'
//
import Navbar from './conponents/Navbar';
import RightNavBar from './conponents/RightNavBar';
import Footer from './conponents/Footer';
//
import {useLang} from './hook/UseLang'
import Top from './svgs/Top'
import { homeContent } from './content/home';
function App() {
  const [lang, setLang] = useLang()
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [rightNav, setRightNav] = useState(false)
  const [srcollPostion,setsrcollPostion]= useState(0)
  const [isLogin, setIsLogin] = useState(true)
  const [isScrolled, setIsScrolled] = useState(false)
  const scrollRef = useRef()
  //
  const handleSetRightNav = (bol) => setRightNav(bol)
  const controlNavbar = () => {
    setShow(scrollRef.current.scrollTop > 100)
    setIsScrolled(scrollRef.current.scrollTop > 1)
    setsrcollPostion(scrollRef.current.scrollTop/(scrollRef.current.scrollHeight))
  };
  useEffect(() => {
    scrollRef.current.scrollTop = 0
  },[location.pathname])
  useEffect(() => {
    // const handleLogin = async () => {
    //   const userName = await prompt('please input username')
    //   const password = await prompt('please input password')
    //   if(userName === 'chFestival' && password === 'PreviewCHF') setIsLogin(true)
    //   // setIsLogin(true)
    // }
    // if(!isLogin){
    //   handleLogin()
    // }
    scrollRef.current.addEventListener('scroll', controlNavbar);
    return () => {
      scrollRef.current.removeEventListener('scroll', controlNavbar);
    };
  },[]);
  const handleSetLang = (lang) => {
    setLang(lang)
  }
  //
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView()
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [hash]);
  const scrollToTop = () => {
    scrollRef.current.scrollTop = 0
  }
  return (
    <div ref={scrollRef} className={`flex h-[100vh] h-min-[100vh] w-[100vw] w-min-[100vw] flex flex-col App lang-${lang==='繁'?'tc':lang==='簡'?'sc':'eng'}` } style={{letterSpacing:1.05,overflowX:"hidden"}}>
      {(isLogin) ? <>
        {['/'].includes(location.pathname) && <Navbar lang={lang} show={show} handleSetRightNav={handleSetRightNav}/>}
        {/* {!['/'].includes(location.pathname) && <Navbar lang={lang} show={true} top={true} handleSetRightNav={handleSetRightNav}/>} */}
        <RightNavBar handleSetLang={handleSetLang} lang={lang} rightNav={rightNav} handleSetRightNav={handleSetRightNav}/>
        <div className='flex flex-1 w-full flex-col'>
          <Routes >
            <Route path="/" element={<Home lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/index.html" element={<Home lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/about" element={<About lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/carpark" element={<Carpark lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/acknowledgement" element={<Acknowledgement lang={lang} handleSetRightNav={handleSetRightNav}/>} />

            {/* event highlight */}
            <Route path="/event-highlights" element={<EventHighlights lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/concerts" element={<Concert lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/performance" element={<Performance lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/photo-exhibition" element={<PhotoEx lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/performance-parade" element={<PerPride lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/market" element={<Market lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/art-installations" element={<ArtInstallations lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            {/* village experience pages */}
            <Route path="village-experience" element={<VillageEx lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/drinking" element={<Drinking lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/screening-session" element={<ScreeningSes lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/simple-ball-game" element={<SimpleBall lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/artkukpo" element={<ArtKukPo lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/Village-experience-5" element={<VillageEx5 lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/Village-experience-6" element={<VillageEx6 lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/Village-experience-7" element={<VillageEx7 lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            <Route path="/Village-experience-8" element={<VillageEx8 lang={lang} handleSetRightNav={handleSetRightNav}/>} />
            
            <Route path="*" element={<PageNotFound lang={lang} handleSetRightNav={handleSetRightNav}/>} />
          </Routes>
        </div>
        <Footer lang={lang}/>
      </> :
      <div>access declined</div>}
      {isScrolled && 
      <div onClick={scrollToTop} className='select-none w-[64px] h-[64px] bg-white rounded-full absolute bottom-[5%] right-[2%] flex flex-col justify-center items-center ml-[24px] text-[12px] text-[#01A334] border border-[#01A334] font-bold cursor-pointer' style={{zIndex: 99999}}>
        <Top />
        {homeContent.backTop[lang]}
      </div>
      }
    </div>
  );
}

export default App;
