import '../App.css';
import { achnow } from '../content/acknowledgement';
import { footerContent } from '../content/Footer';
import Navbar from '../conponents/Navbar'
//
import LogoSvg from '../svgs/LogoSvg'
import ShortDivider from '../conponents/ShortDivider'
import { useNavigate } from 'react-router-dom';
import { homeContent } from '../content/home';
import { s3_PREFIX } from '../config';

function App({lang,handleSetRightNav}) {
    const ab = achnow
    const ft = footerContent
    const navigate = useNavigate();
    function navigating(str){ navigate(str) }
  return (
    <div className='relative flex flex-col w-full text-[black] h-full bg-[#ffffff]'>
        {/*header*/}
        {/* <div className='w-full bg-white flex flex-row justify-between items-center' style={{borderBottom:'18px solid #01A334', zIndex: 99}}>
            <Navbar lang={lang} top={true} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
        </div> */}
        <Navbar sticky={true} lang={lang} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>

        {/*header*/}
        <div className='w-full bg-[#E1F5C9]'>
            <img className='w-full' src={s3_PREFIX+'/ackbg.jpg'} />
            <div className='flex flex-col items-center p-page'>
                <div className='home-title text-wrap border text-[#000000] border-[#01A334] border-[4px] rounded-full w-full max-w-[600px] bg-[#E1F5C9]'>{ab.title[lang]}</div>
                <ShortDivider width={'100%'} maxW={380} py={4} color={'#01A334'}/>
            </div>
            <div className='w-full flex flex-col justify-start p-concert mb-[100px]'>
                <div className='relative flex items-start'>
                    <div className='text-[#008735] home-25 relative text-nowrap' style={{zIndex:2}}>{ab.title2[lang]}</div>
                    <div className='h-[50%] absolute bottom-0 bg-[#ffffff]' style={{zIndex:1,width:lang==='EN'?470:350}}></div>
                </div>
                <div className='text-justify home-22-text' style={{whiteSpace:'pre-wrap'}}>{ab.text[lang]}</div>
                <div className='flex flex-row items-center mt-[24px]' style={{gap:24}}>
                    <a target='_blink' href={'https://www.facebook.com/countrysideconservationhk/'}>
                        <img src={s3_PREFIX+'/icon-fc.png'}></img>
                    </a>
                    <a target='_blink' border href={'https://www.instagram.com/countrysideconservationhk/'}>
                        <img src={s3_PREFIX+'/icon-insta.png'}></img>
                    </a>
                </div>
                <div className='relative flex items-start mt-[20px]'>
                    <div className='text-[#008735] home-25 relative text-nowrap' style={{zIndex:2}}>{ab.enquiry[lang]}</div>
                    <div className='h-[50%] absolute bottom-0 bg-[#ffffff]' style={{zIndex:1,width:lang==='EN'?470:350}}></div>
                </div>
                <div className='text-[#008735] home-25 relative text-nowrap self-start' style={{zIndex:2}}>+852 3695 0962</div>
                
            </div>
        </div>
        <div>
            <div>
                <div className='flex flex-row items-start flex-wrap p-concert mt-[12px]' style={{gap: 128}}>
                    <div className=''>
                        <div className='relative flex items-start'>
                            <div className='text-[#008735] home-22 relative' style={{zIndex:2}}>{ft.footR1[lang]}</div>
                            <div className='w-[245px] h-[50%] absolute bottom-0 bg-[#99DFAD80]' style={{zIndex:1}}></div>
                        </div>
                        <img src={s3_PREFIX+'/ackcco-new.png'} style={{height:undefined}}className='flex-1 max-w-[330px] mt-[45px]'></img>
                    </div>
                    <div className=''>
                        <div className='relative flex items-start'>
                            <div className='text-[#008735] home-22 relative' style={{zIndex:2}}>{ft.footR2[lang]}</div>
                            <div className='w-[245px] h-[50%] absolute bottom-0 bg-[#99DFAD80]' style={{zIndex:1}}></div>
                        </div>
                        <img src={s3_PREFIX+'/ackpoly.png'} style={{height:undefined}}className='flex-1 max-w-[275px] mt-[45px]'></img>
                    </div>

                </div>
                <div>
                <div className='p-concert my-[128px]'>
                    <div className='relative flex items-start'>
                        <div className='text-[#008735] home-22 relative' style={{zIndex:2}}>{ab.suport[lang]}</div>
                        {lang === 'EN' && <div className='w-full max-w-[600px] h-[50%] absolute bottom-0 bg-[#99DFAD80]' style={{zIndex:1}}></div>}
                        {lang !== 'EN' && <div className='w-full max-w-[300px] h-[50%] absolute bottom-0 bg-[#99DFAD80]' style={{zIndex:1}}></div>}
                    </div>
                    <div className='w-full max-w-[1300px] flex flex-row mt-[45px] flex-wrap items-end' style={{gap: 64}}>
                        <img src={'/tourismlogo.png'} className='max-w-[280px]' style={{height: undefined, objectFit:'contain'}}></img>
                        <img src={'/shataulogo.png'} className='max-w-[160px]'  style={{height: undefined, objectFit:'contain'}}></img>
                    </div>
                   
                    {/* <div className='home-22 my-[71px] text-left'>{ab.text2[lang]}</div> */}
                </div>
                </div>
                <div>
                <div className='p-concert my-[128px]'>
                    {/* <div className='relative flex items-start'>
                        <div className='text-[#008735] home-22 relative' style={{zIndex:2}}>{ab.suport[lang]}</div>
                        <div className='w-[245px] h-[50%] absolute bottom-0 bg-[#99DFAD80]' style={{zIndex:1}}></div>
                    </div> */}
                    <div className='w-full max-w-[1300px] flex flex-row mt-[45px] flex-wrap items-end' style={{gap: 64}}>
                        <img src={'/ackcuhk.png'} className='w-full max-w-[540px]' style={{height: undefined, objectFit:'contain'}}></img>
                        <img src={'/ackuhk.png'} className='w-full max-w-[400px]'  style={{height: undefined, objectFit:'contain'}}></img>
                    </div>
                   
                    {/* <div className='home-22 my-[71px] text-left'>{ab.text2[lang]}</div> */}
                    </div>
                </div>
            </div>
        </div>
        <div>

        </div>
    </div>
  );
}

export default App;
