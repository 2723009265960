export const drinkingContent = {
    titleL:{
        EN:'Taste @ Kuk Po',
        繁:'味@谷埔',
        簡:'味@谷埔',
    },
    titleR:{
        EN:'Drinking',
        繁:'工作坊',
        簡:'工作坊',
    },
    post1Title:{
        EN:'Drinking in the Moonlight',
        繁:'月下獨酌——賞茶工作坊',
        簡:'月下独酌——赏茶工作坊',
    },
    post1Text:{
        EN:['Under the moonlit sky and a cool breeze, experience the essence of Kuk Po’s past while sipping on unique drinks served in handmade clay cups crafted by locals.','Workshops feature ingredients like roselle and pomelo leaves, harvested right from Kuk Po’s soil, infusing each sip with the authentic flavours of the land. Artist Muses Sze has collected local clay in Kuk Po together with the project team and students of HKICC Lee Shau Kee School of Creativity. Through the process of collecting, washing wild clay and glaze testing, the clay were finally made into a series of pottery for the villagers to serve Hakka banquets. These clay cups would be used in the workshop, just like the soil, in addition to nurture plant life and history of Kuk Po, it can also be transformed to functional food-ware.'],
        繁:['皓皓月色，清風朗月，品嚐谷埔陶泥杯盛載的特色飲品，欣賞光影裝置，細述鄉村舊話。','工作坊用上谷埔種的農作物，洛神花、柚葉等材料⼊茶，讓遊人可嚐到本地作物的香味。藝術家與項目團隊、香港兆基創意書院的同學一起到谷埔上山取泥，經歷過滿身汗水與泥土，亦多次對本地泥土的處理、試泥和試釉等過程。我們克服了幾重考驗，更珍惜這些谷埔泥土，最後製作成給村民用以客家飲宴的盛載器皿。這些陶泥杯將會在工作坊中使用，就像是泥土除了孕育谷埔的生命和歷史外，也能化作食物器物的泥。'],
        簡:['皓皓月色，清风朗月，品尝谷埔陶泥杯盛载的特色饮品，欣赏光影装置，细述乡村旧话。','工作坊用上谷埔种的农作物，洛神花、柚叶等材料⼊茶，让游人可尝到本地作物的香味。艺术家与项目团队、香港兆基创意书院的同学一起到谷埔上山取泥，经历过满身汗水与泥土，亦多次对本地泥土的处理、试泥和试釉等过程。我们克服了几重考验，更珍惜这些谷埔泥土，最后制作成给村民用以客家饮宴的盛载器皿。这些陶泥杯将会在工作坊中使用，就像是泥土除了孕育谷埔的生命和历史外，也能化作食物器物的泥。'],
    },
    post1Date1:{
        EN:'19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'19/1(日)、25/1(六)、26/1(日)',
        簡:'19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time1:{
        EN:'5:00 pm – 7:00 pm (25 minutes per session, 4 sessions per day)',
        繁:'下午5:00 – 7:00',
        簡:'下午5:00 – 7:00',
    },
    post1Loca1:{
        EN:'Grassland outside LEE’s Mansion, Tin Sum',
        繁:'田心李氏大宅外',
        簡:'田心李氏大宅外',
    },
    specialTitle:{
        EN:'Distributing physical tickets onsite from 1:00 pm to 5:00 pm',
        繁:'活動當日下午1時至5時現場派籌',
        簡:'活动当日下午1时至5时现场派筹',
    },
    specialText1:{
        EN:'(60 tickets per day)',
        繁:'(每日60個名額)',
        簡:'(每日60个名额)',
    },
    specialText2:{
        EN:'First come first served!',
        繁:'先到先得，額滿即止',
        簡:'先到先得，额满即止',
    },
    post2Head:{
        EN:'Introduction',
        繁:'關於創作人',
        簡:'关于创作人',
    },
    post2Title:{
        EN:'Muses Sze Mei Ting',
        繁:'施美婷',
        簡:'施美婷',
    },
    post2Text:{
        EN:['Muses Sze Mei Ting is an artist based in Hong Kong. She graduated from the Fine Arts Department of the Chinese University of Hong Kong. Then she was awarded International Master’s Bursary and studied MFA at the University of Reading (UoR), then obtained her Master’s Degree of Fine Arts in 2016. Since 2021, she has curated a project- ”The Workshop Guide to Ceramics by Hong Kong Ceramics Artist”, combining the research of Hong Kong ceramics art with a practical guide to ceramics. She now teaches the ceramics course in HKSC.'],
        繁:['在香港中文大學取得藝術學士學位。期後獲英國雷丁大學的獎學金及修讀藝術碩士課程，於 2016 年取得碩士學位。2021年起策劃研究項目-《香港陶瓷藝術: 工作室導覽及創作指導》，探討香港陶瓷藝術與學習陶藝技巧。現於創意書院教授陶藝課程。'],
        簡:['在香港中文大学取得艺术学士学位。期后获英国雷丁大学的奖学金及修读艺术硕士课程，于 2016 年取得硕士学位。2021年起策划研究项目-《香港陶瓷艺术: 工作室导览及创作指导》，探讨香港陶瓷艺术与学习陶艺技巧。现于创意书院教授陶艺课程。'],
    }
}