import '../App.css';
import { homeContent } from '../content/home';
import Navbar from '../conponents/Navbar'
import { useEffect, useState } from 'react';
//
import { s3_PREFIX } from '../config';
import ShortDivider from '../conponents/ShortDivider'
import { useNavigate, useLocation } from 'react-router-dom';
const sec4Arr = [
    {
        img: '/images/home/sec4-1.jpg',
        path: '/concerts'
    },
    {
        img: '/images/home/sec4-2.jpeg',
        path: '/performance'
    },
    {
        img: '/images/home/sec4-3.png',
        path: '/art-installations'
    },
    {
        img: '/images/home/sec4-4.jpg',
        path: '/photo-exhibition'
    },
    {
        img: '/images/home/sec4-5.jpg',
        path: '/performance-parade'
    },
    {
        img: '/images/home/sec4-6.jpeg',
        path: '/market'
    },
]

function App({lang,handleSetRightNav}) {
    const [boatPop, setBoatPop] = useState(false)
    const [firstpop, setFirstPop] = useState(true)
    const hc = homeContent
    const navigate = useNavigate();
 
    function navigating(str){
        navigate(str)
    }
    const handleSaveImage = (imageUrl) => {
        const link = document.createElement('a');
        link.href = imageUrl;
        let arr = imageUrl.split('/')
        link.download = arr[arr.length-1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    useEffect(() => {
        setFirstPop(true)
    },[lang])
  return (
    <div className='flex flex-col w-full text-[black] h-full bg-[#fff]'>
        {boatPop && <div className='absolute w-[100vw] h-[100vh] top-0 left-0 flex flex-row items-center justify-center' style={{zIndex:9999999, backdropFilter: 'blur(10px)'}}>
            <div className='bg-[#ffffff] d-50w-m-90w h-[70%] rounded-xl border border-[4px] border-[#01A334] relative flex flex-col items-center justify-center px-[24px]'>
                <div onClick={() => setBoatPop(false)} className='w-[50px] h-[50px] rounded-full border border-[2px] border-[#01A334] absolute top-3 right-5 flex flex-row items-center justify-center cursor-pointer select-none'>
                    <img src={s3_PREFIX+'/close.png'}></img>
                </div>
                <div className='font-bold text-[18px] text-left'>
                    {homeContent.boatPopTitle[lang]}
                </div>
                <div className='text-left mt-[12px]'>
                    {homeContent.boatPopText[lang]}
                </div>
                {lang !== 'EN' && <a target='_blink' href='https://art-mate.net/tc/doc/80542' className='max-w-[300px] mt-[31px] cursor-pointer w-full h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-between px-[30px]'>
                    <div className='home-22 text-white'>{hc.boatPopNext[lang]}</div>
                    <div className='max-w-[25px] h-[25px] bg-white flex flex-1 items-center justify-center rounded-full'>
                        <div className='w-[4px] h-[4px] bg-[#898989] rounded-full'></div>
                    </div>
                </a>}
                {lang === 'EN' && <a target='_blink' href='https://art-mate.net/en/doc/80542' className='max-w-[300px] mt-[31px] cursor-pointer w-full h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-between px-[30px]'>
                    <div className='home-22 text-white'>{hc.boatPopNext[lang]}</div>
                    <div className='max-w-[25px] h-[25px] bg-white flex flex-1 items-center justify-center rounded-full'>
                        <div className='w-[4px] h-[4px] bg-[#898989] rounded-full'></div>
                    </div>
                </a>}
            </div>
        </div>}
        {/* {firstpop && <div className='absolute w-[100vw] h-[100vh] top-0 left-0 flex flex-row items-center justify-center' style={{zIndex:9999999, backdropFilter: 'blur(10px)'}}>
            <div className='bg-[#ffffff] d-40w-m-90w h-[40%] rounded-xl border border-[4px] border-[#01A334] relative flex flex-col items-center justify-center px-[24px]'>
                <div onClick={() => setFirstPop(false)} className='w-[50px] h-[50px] rounded-full border border-[2px] border-[#01A334] absolute top-3 right-5 flex flex-row items-center justify-center cursor-pointer select-none'>
                    <img src={s3_PREFIX+'/close.png'}></img>
                </div>
                <div className='font-bold text-[18px] text-left'>
                    {homeContent.popNewsTitle[lang]}
                </div>
                <div className='text-left mt-[12px]'>
                    {homeContent.popNews[lang]}
                </div>
            </div>
        </div>} */}
        {/* <div className='w-full absolute top-0 left-0'>
            <Navbar lang={lang} top={true} handleSetRightNav={handleSetRightNav}/>
        </div> */}
        <div className='w-full'>
            <Navbar bg={'#01D5C9'} lang={lang} top={true} handleSetRightNav={handleSetRightNav}/>
            <div className='w-full' style={{aspectRatio:3840/2690, height:undefined}}>
                {lang === 'EN' && <img className='w-full' src={'/images/homebgen.jpg'} />}
                {lang === '繁' && <img className='w-full' src={'/images/homebgtc.jpg'} />}
                {lang === '簡' && <img className='w-full'src={'/images/homebgsc.jpg'} />}
            </div>
            <div className='bg-[#01A334]'>
                <div className='flex flex-col w-full p-page'>
                    <img className='w-[80px] self-end pl-[16px]' src={s3_PREFIX+'/images/home/homeflower.png'} style={{objectFit:'contain'}}></img>
                    <div className='relative w-full flex flex-row flex-wrap items-center justify-center p-page' style={{gap:'58px'}}>
                        <div className='text-white flex flex-col items-center min-w-[300px]' style={{flex: 1}}>
                            <div className='home-home-title border border-[4px] rounded-full w-full px-[14px]'>{hc.sec1Title[lang]}</div>
                            <ShortDivider width={'100%'} maxW={380}/>
                            <div className='flex flex-col items-center mt-[55px] text-justify'>{
                                hc.sec1Text1[lang]?.map((words) => <div className='home-25'>{words}</div>)
                            }</div>
                            <div className='flex flex-col items-center mt-[60px] text-justify'>{
                                hc.sec1Text2[lang]?.map((words) => <div className='home-25'>{words}</div>)
                            }</div>
                        </div>
                        <div className='flex flex-row items-center justify-center' style={{flex: 1}}>
                            <div className='w-[20%] text-white flex flex-col flex-1 items-center bg-[#008735B2] border border-[4px] home-sec2-pd'>
                                    <div className='flex flex-row items-start'>
                                        <div className='home-25 font-black text-nowrap mr-[4px]'>{hc.sec1DateTitle[lang]}</div>
                                        <div className=''>
                                            <div className='home-25 text-left'>{hc.sec1DateText1[lang]}</div>
                                            <div className='home-25 text-left'>{hc.sec1DateText2[lang]}</div>
                                        </div>
                                    </div>
                                    <ShortDivider width={'90%'} py={22}/>
                                    <div className='flex flex-col items-end'>
                                        <div className='home-25'><span className='font-black'>{hc.sec1TimeTitle[lang]}</span>{hc.sec1TimeText[lang]}</div>
                                    </div>
                                    <ShortDivider width={'90%'} py={22}/>
                                    <div className='flex flex-col items-end'>
                                        <div className='home-25'><span className='font-black'>{hc.sec1LocaTitle[lang]}</span>{hc.sec1LocaText[lang]}</div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <img className='w-[100px] self-start pr-[16px]' src={s3_PREFIX+'/images/home/hometree.png'} style={{objectFit:'contain'}}></img>
                </div>
                <div className='relative w-full bg-[#01A334]'>
                    <img className='w-full' src={s3_PREFIX+'/images/home/stack1.png'}></img>
                    <img className='w-full absolute top-0 left-0' src={s3_PREFIX+'/images/home/stack2.png'}></img>
                    <img className='w-full absolute top-0 left-0' src={s3_PREFIX+'/images/home/stack3.png'}></img>
                </div>
            </div>
            {/* Section 2 */}
            <div className='relative flex flex-col items-center bg-[#E4E9E6]'>
                <div id='info' className='absolute top-[-150px]'></div>
                <div className='w-full flex flex-col items-center'>
                    <div className='absolute top-0 left-0 flex flex-col w-full h-full items-center p-page' style={{zIndex:0}}>
                        <div className='home-title text-nowrap border border-[#01A334] border-[4px] rounded-full w-full max-w-[600px] bg-[#E4E9E6]'>{hc.sec2Title[lang]}</div>
                        <ShortDivider width={'100%'} maxW={380} py={4} color={'#01A334'}/>
                    </div>
                    <img className='w-full' src={s3_PREFIX+'/images/home/wave1.jpg'}></img>
                </div>
                <div className='w-full flex row-col items-center justify-center bg-[#D0D6D0] p-page' style={{zIndex:2, gap: 14}}>
                    <div className='flex-1 flex flex-col h-full timetable-container justify-between'>
                        <div className='home-30'>{hc.sec2Time[lang]}</div>
                        <div className='w-full bg-white min-h-[550px] flex items-center justify-center rounded-xl px-[14px] py-[14px] overflow-hidden flex-1'>
                            <img className='h-full' src={'/images/home/timetablepreview.png'} style={{height:undefined,aspectRatio:979/1889}}></img>
                        </div>
                        <div className='w-full flex row-col items-center justify-center mt-[50px]' style={{gap:24}}>
                            <div className='cursor-pointer w-[200px] h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-between px-[30px] cursor-pointer' onClick={()=>{handleSaveImage('/images/home/timetabledownload.jpg')}}>
                                <div className='home-22 text-white'>{hc.download[lang]}</div>
                                <div className='max-w-[25px] h-[25px] bg-white flex flex-1 items-center justify-center rounded-full'>
                                    <div className='w-[4px] h-[4px] bg-[#01A334] rounded-full'></div>
                                </div>
                            </div>
                            <div className='cursor-pointer w-[200px] h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-between px-[30px] cursor-pointer' onClick={()=>{window.open('/images/home/timetabledownload.jpg', '_blank');}}>
                                <div className='home-22 text-white'>{hc.zoomin[lang]}</div>
                                <div className='max-w-[25px] h-[25px] bg-white flex flex-1 items-center justify-center rounded-full'>
                                    <div className='w-[4px] h-[4px] bg-[#01A334] rounded-full'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex-1 flex flex-col h-full timetable-container justify-between'>
                        <div className='home-30'>{hc.sec2Map[lang]}</div>
                        <div className='bg-white flex items-center justify-center rounded-xl py-[14px] overflow-hidden flex-1 min-h-[550px] map-min-h'>
                            <img className='w-[90%]' src={'/images/home/mappreview.jpg'} style={{height:undefined,aspectRatio:1920/1358,objectFit:'contain'}}></img>
                        </div>
                        <div className='w-full flex row-col items-center justify-center mt-[50px]' style={{gap:24}}>
                            <div className='cursor-pointer w-[200px] h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-between px-[30px] cursor-pointer' onClick={()=>{handleSaveImage('/images/home/mapdownload.jpg')}}>
                                <div className='home-22 text-white'>{hc.download[lang]}</div>
                                <div className='max-w-[25px] h-[25px] bg-white flex flex-1 items-center justify-center rounded-full'>
                                    <div className='w-[4px] h-[4px] bg-[#01A334] rounded-full'></div>
                                </div>
                            </div>
                            <div className='cursor-pointer w-[200px] h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-between px-[30px] cursor-pointer' onClick={()=>{window.open('/images/home/mappreview.jpg', '_blank');}}>
                                <div className='home-22 text-white'>{hc.zoomin[lang]}</div>
                                <div className='max-w-[25px] h-[25px] bg-white flex flex-1 items-center justify-center rounded-full'>
                                    <div className='w-[4px] h-[4px] bg-[#01A334] rounded-full'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Section 3 */}
            <img className='w-full' src={s3_PREFIX+'/images/home/wave2.jpg'}></img>
            <div className='relative flex flex-col items-center bg-[#E4E9E6]'>
                <div className='flex flex-col w-full h-full items-center p-page' style={{zIndex:0}}>
                    <div className='transportation-title text-nowrap border border-[#01A334] border-[4px] rounded-full w-full bg-[#E4E9E6]' style={{maxWidth:lang==='EN'?1100:484}}>{hc.sec3Title[lang]}</div>
                    <ShortDivider width={'100%'} maxW={380} py={4} color={'#01A334'}/>
                </div>
                <div className='home-sec2-pad w-full'>
                    <div className='w-full flex row-col flex-wrap items-center justify-between' style={{gap:24}}>
                        <div className='flex flex-1 flex-col h-full justify-between'>
                            <div className='flex flex-col items-start'>
                                <div className='text-nowrap home-25 mb-[24px]'>{hc.sec3BoatTitle1[lang]}</div>
                                {hc.sec3BoatText1[lang]?.map((text) => <div className='text-left home-22-text my-[4px]'>{text}</div>)}
                            </div>
                            <div className='flex flex-col items-start mt-[80px] mb-[8px]'>
                                <div className='text-nowrap home-25 mb-[24px]'>{hc.sec3BoatTitle2[lang]}</div>
                                {hc.sec3BoatText2[lang]?.map((text) => <div className='text-left home-22-text'>{text}</div>)}
                            </div>
                            <a target='_blink' href={'https://www.es.police.gov.hk/eserv-online-portal-ui/#/pages/e-services-application-forms/10'} className='mt-[40px] cursor-pointer w-full max-w-[521px] h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-center px-[30px] mb-[8px]'>
                                <div className='home-22 text-white'>{hc.sec3BoatBtn[lang]}</div>
                                {/* <div className='min-w-[25px] h-[25px] bg-white flex items-center justify-center rounded-full'>
                                    <div className='w-[4px] h-[4px] bg-[#01A334] rounded-full'></div>
                                    </div> */}
                            </a>
                        </div>
                        {/* <div style={{aspectRatio: 1}} className='pad-boat-ticket max-w-[455px] min-w-[300px] flex flex-1 items-center justify-end'>
                            <img className='flex-1 rounded' src={s3_PREFIX+'/images/home/transportation.jpg'} style={{height:undefined, aspectRatio: 447/454}}></img>
                            </div> */}
                    <div className='flex-1 flex flex-col'>
                        <div className='w-full bg-white flex items-center justify-center rounded-[45px] px-[12px] py-[10px] overflow-hidden'>
                            {lang === 'EN' && <img className='w-full' src={'/images/home/transporten.png'} style={{height:undefined,aspectRatio:1920/2101,objectFit:'contain'}}></img>}
                            {lang === '繁' && <img className='w-full' src={'/images/home/transportcn.png'} style={{height:undefined,aspectRatio:1920/2101,objectFit:'contain'}}></img>}
                            {lang === '簡' && <img className='w-full' src={'/images/home/transportsc.png'} style={{height:undefined,aspectRatio:1920/2101,objectFit:'contain'}}></img>}
                        </div>
                        <div>
                            {lang === 'EN' && <div className='text-start'>Details and times are subject to the <a target='_blink' className='underline' href='https://www.td.gov.hk/en/transport_in_hong_kong/public_transport/index.html'>Transport Department</a> and <a target='_blink' className='underline' href='https://www.kmb.hk/'>Kowloon Motor Bus</a> website announcements.</div>}
                            {lang === '繁' && <div className='text-start'><span className='font-bold'>備註：</span>詳情及時間以<a target='_blink' href='https://www.td.gov.hk/tc/transport_in_hong_kong/public_transport/index.html' className='underline'>運輸署網站</a>及<a target='_blink' className='underline' href='https://www.kmb.hk/'>九巴網站</a>公布為準。</div>}
                            {lang === '簡' && <div className='text-start'><span className='font-bold'>備註：</span>详情及时间以<a target='_blink' href='https://www.td.gov.hk/sc/transport_in_hong_kong/public_transport/index.html' className='underline'>运输署网站</a>及<a target='_blink' className='underline' href='https://www.kmb.hk/'>九巴网站</a>公布为准。</div>}
                        </div>
                        <div className='w-full flex row-col items-center justify-center mt-[50px]' style={{gap:24}}>
                            <div className='cursor-pointer w-[300px] h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-between px-[30px] cursor-pointer' onClick={()=>{handleSaveImage(lang === 'EN' ? '/images/home/transporten.png' : lang === '繁' ? '/images/home/transportcn.png' : '/images/home/transportsc.png')}}>
                                <div className='home-22 text-white'>{hc.download[lang]}</div>
                                <div className='max-w-[25px] h-[25px] bg-white flex flex-1 items-center justify-center rounded-full'>
                                    <div className='w-[4px] h-[4px] bg-[#01A334] rounded-full'></div>
                                </div>
                            </div>
                            <div className='cursor-pointer w-[300px] h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-between px-[30px] cursor-pointer' onClick={()=>{window.open(lang === 'EN' ? '/images/home/transporten.png' : lang === '繁' ? '/images/home/transportcn.png' : '/images/home/transportsc.png', '_blank');console.log(lang === 'EN')}}>
                                <div className='home-22 text-white'>{hc.zoomin[lang]}</div>
                                <div className='max-w-[25px] h-[25px] bg-white flex flex-1 items-center justify-center rounded-full'>
                                    <div className='w-[4px] h-[4px] bg-[#01A334] rounded-full'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='mt-[126px] w-full flex row-col items-center justify-between flex-wrap relative'>
                        <div id='ticket' className='absolute top-[-100px]'></div>
                        <div className='flex flex-1 w-full flex-col h-full justify-between mb-[8px]'>
                            <div className='flex flex-col items-start'>
                                <div className='text-left home-25 mb-[24px]'>{hc.sec3TicketTitle[lang]}</div>
                                <div className='home-22-text text-left text-wrap' style={{whiteSpace:'pre-wrap'}}>{hc.sec3TicketText[lang]}</div>
                                <div className='home-22-text text-left text-wrap mt-[12px]' style={{whiteSpace:'pre-wrap'}}><span className='font-bold'>{hc.popNewsTitle[lang]}</span> {hc.popNews[lang]}</div>
                                {/* <div className='home-22-text-500 mt-[17px] text-[red] text-left' style={{whiteSpace:'pre-wrap'}}>
                                    {hc.sec3BoatText3[lang]}
                                </div> */}
                            </div>
                            <div className='flex flex-col items-start mt-[80px]' style={{gap:4}}>
                                <div className='flex flex-row items-start'>
                                    <div className='home-22'>{hc.sec1DateTitle[lang]}</div>
                                    <div className='home-22-text flex-1'>{hc.sec3TicketDateData[lang]}</div>
                                </div>
                                <div className='flex flex-row items-start'>
                                    <div className='home-22'>{hc.sec1TimeTitle[lang]}</div>
                                    <div className='flex flex-col items-start'>{hc.sec3TicketTimeData[lang]?.map((text) => <div className='home-22-text'>{text}</div>)}</div>
                                </div>
                                {lang !== 'EN' && <div className='flex flex-row items-start'>
                                    <div className='no-to-wrap home-22'>{hc.sec1LocaTitle[lang]}</div>
                                    <div className='flex flex-col items-start'>{hc.sec3TicketLocaData[lang]?.map((text) => <div className='home-22-text'>{text}</div>)}</div>
                                </div>}
                                <div className='flex flex-row items-start'>
                                    {lang !== 'EN' && <div className='no-to-wrap home-22' style={{opacity:0}}>{hc.remarks[lang]}</div>}
                                    <div className='flex flex-col items-start ml-[2px]'>{hc.sec3TicketLocaData2[lang]?.map((text) => <div className='text-left home-22-text' style={{fontWeight:'bold'}}>{text}</div>)}</div>
                                </div>
                                <div className='flex flex-row items-start mt-[36px]'>
                                    <div className='text-nowrap home-22'>{hc.remarks[lang]}</div>
                                    <div className='flex flex-col items-start ml-[2px]'>
                                        {lang === 'EN' &&<div className='home-22-text text-left'>1. Please&nbsp;<a className='underline' target='_blink' href='https://www.es.police.gov.hk/eserv-online-portal-ui/#/pages/e-services-application-forms/10'>apply for a Closed Area Permit (CAP)</a>  at least 3 working days prior to the day of visit, and must hold a valid Closed Area Permit (CAP) on the day of visit to board the boat.</div>}
                                        {lang === '繁' && <div className='home-22-text text-left'>(1)請於到訪日期前最少三個工作天<a className='underline' target='_blink' href='https://www.es.police.gov.hk/eserv-online-portal-ui/#/pages/e-services-application-forms/10'>申請邊境禁區許可證</a>， 並須於到訪當天持有有效邊境禁區許可證方可上船。</div>}
                                        {lang === '簡' &&<div className='home-22-text text-left'>(1)请于到访日期前最少三个工作天<a className='underline' target='_blink' href='https://www.es.police.gov.hk/eserv-online-portal-ui/#/pages/e-services-application-forms/10'>申请边境禁区许可证</a>， 并须于到访当天持有有效边境禁区许可证方可上船。</div>}
                                        {hc.remarks1[lang]?.map((text) => <div className='home-22-text text-left'>{text}</div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center justify-end min-w-[300px]'>
                            <div style={{aspectRatio: 1}} className='pad-boat-ticket max-w-[455px] border border-[#000000] border-[2px] rounded-3xl flex flex-col items-center justify-center flex-1'>
                                <div className='px-[24px]'>
                                    <img className='flex-1' src='/images/home/ticket.png' style={{height:undefined, aspectRatio: 372/189}}></img>
                                    <div onClick={() => setBoatPop(true)} className='mt-[31px] cursor-pointer w-full h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-between px-[30px]'>
                                        <div className='home-22 text-white'>{hc.sec3TicketBuy[lang]}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-[126px] w-full flex row-col items-center justify-between flex-wrap relative'>
                        <div className='flex flex-1 w-full flex-col h-full justify-between mb-[8px] mr-[12px] '>
                            <div className='flex flex-col items-start'>
                                <div className='text-left home-25 mb-[24px]'>{hc.sec3Car[lang]}</div>
                                <div className='flex flex-1 flex-col justify-between text-[#000000]'>
                                    {hc.carText[lang].map((word) => <div className='home-22-text text-justify mb-[6px] text-[#000000]'>{word}</div>)}
                                    {lang === 'EN' &&<div className='home-22-text text-left mt-[18px]'><a className='underline font-bold' target='_blink' href='https://docs.google.com/forms/d/e/1FAIpQLSfKu2XLhktpHcnJDLjmld9pfbtLf5nEJFW5DCI4Sr9D7Z-eyg/viewform'>Parking reservation and details</a></div>}
                                    {lang === '繁' && <div className='home-22-text text-left mt-[18px]'><a className='underline font-bold' target='_blink' href='https://docs.google.com/forms/d/e/1FAIpQLSfKu2XLhktpHcnJDLjmld9pfbtLf5nEJFW5DCI4Sr9D7Z-eyg/viewform'>車位預約及詳情</a></div>}
                                    {lang === '簡' &&<div className='home-22-text text-left mt-[18px]'><a className='underline font-bold' target='_blink' href='https://docs.google.com/forms/d/e/1FAIpQLSfKu2XLhktpHcnJDLjmld9pfbtLf5nEJFW5DCI4Sr9D7Z-eyg/viewform'>车位预约及详情</a></div>}
                                    {/* <div className='home-22-text text-left mt-[12px]'>{hc.whatsapp[lang]}</div>
                                    <a target='_blink' href='https://wa.me/90884428' className='underline home-22-text text-left'>+852 9088 4428</a> */}
                                </div>                                
                                {/* <div className='home-22-text-500 mt-[17px] text-[red] text-left' style={{whiteSpace:'pre-wrap'}}>
                                    {hc.sec3BoatText3[lang]}
                                </div> */}
                            </div>
                        </div>
                        <div className='flex items-center justify-end min-w-[300px]'>
                            <div style={{aspectRatio: 1}} className='pad-boat-ticket w-full max-w-[700px] rounded-3xl flex flex-col items-center justify-center flex-1'>
                                    <img className='w-full' src='/images/home/carmap.png' style={{height:undefined, aspectRatio: 1340/1001}}></img>
                            </div>
                        </div>
                    </div>
                    {/* <div onClick={() => navigate(`/carpark/?lang=${lang}`)} className='my-[48px] cursor-pointer w-full max-w-[450px] h-[66px] bg-[#01A334] rounded-full flex flex-row items-center justify-between px-[30px]'>
                        <div className='home-22 text-white'>{hc.sec3Car[lang]}</div>
                    </div> */}
                </div>
            </div>
            {/* Section 4 */}
            <img className='w-full' src={s3_PREFIX+'/images/home/wave3.jpg'}></img>
            <div className='relative flex flex-col items-center bg-[#E1F5C9]'>
                <div className='flex flex-col w-full h-full items-center p-page' style={{zIndex:0}}>
                    <div className='home-title text-nowrap border border-[#01A334] border-[4px] rounded-full w-full max-w-[484px] bg-[#E1F5C9]'>{hc.sec4Title[lang]}</div>
                    <ShortDivider width={'100%'} maxW={380} py={4} color={'#01A334'}/>
                </div>
                <div className='w-full max-w-[1800px] p-page mt-[131px]'>
                    <div className='w-full flex row-col flex-wrap justify-evenly items-start-to-center'>
                        {sec4Arr?.map((obj,i) => <div key={'sec4' + i} className='flex flex-col items-center justify-between mb-[124px] cursor-pointer max-w-[480px] min-h-[550px]' onClick={()=>{navigating(`${obj?.path}?lang=${lang}`)}}>
                            <img className='w-[480px] rounded-2xl' src={s3_PREFIX+obj.img} style={{height:undefined,aspectRatio: 4/3, objectFit:'cover'}}></img>
                            <div className='mt-[51px] title-text-1 text-[#FF685B] text-wrap'>{hc[`sec4Title${i+1}`][lang]}</div>
                            <div className='mt-[16px] home-25 border text-white bg-[#01A334] rounded-full min-w-[200px] px-[16px] py-[4px]'>{
                                [0,1].includes(i) ? hc.sec4BtnSound[lang]
                                : [2,3,4].includes(i) ? hc.sec4BtnColor[lang]
                                : hc.sec4BtnTaste[lang]
                            }{hc.sec4BtnEnd[lang]}</div>
                        </div>)}
                    </div>
                </div>
                <div className='w-full p-page my-[40px] home-22-text'>
                    {homeContent.special1[lang]}
                </div>
            </div>
            
        </div>
    </div>
  );
}

export default App;
