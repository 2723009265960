export const photoEx = {
    titleL:{
        EN:'Sight @ Kuk Po',
        繁:'色@谷埔',
        簡:'色@谷埔',
    },
    titleR:{
        EN:'Photo Exhibition',
        繁:'攝影展',
        簡:'摄影展',
    },
    title2:{
        EN:'Introduction',
        繁:'攝影師介紹',
        簡:'摄影师介绍',
    },
    post1Title:{
        EN:'"A Stroll through Kuk Po: The Beauty of Return” Photo Exhibition',
        繁:' 「回——谷埔走一回」攝影展 ',
        簡:' 「回——谷埔走一回」摄影展 ',
    },
    post1Text:{
        EN:'We find ourselves in an era rich in information and material abundance. Yet, whenever we seek tranquillity, the countryside remains a cherished refuge. The harmony between the countryside and nature may explain our affinity for rural life. Beyond this, the countryside holds a treasure trove of simplicity and traditional customs that urban dwellers have long missed. It encompasses the intricate connections among people, land, sky, and the essence of humanity itself. \r\n\r\nThe Hong Kong International Photography Festival (HKIPF) presents countless possibilities through old and contemporary photographs, showcasing these timeless stories and legends, which we can never tire of hearing, are deeply intertwined with the concept of "return"—returning home, returning to one’s roots, looking back, rejuvenating, responding, giving back, reminiscing, returning to origins, sharing, savoring, honoring, reflecting, flowing back... and what else? It’s time for us to have a stroll through Kuk Po again!',
        繁:'我們正處身一個資訊發達、物質豐盛的世代，但每當我們想安靜下來的時候，鄉郊仍然是我們非常嚮往的地方。鄉郊和大自然二而為一，這或許就是人們喜歡鄉郊的原因，但除此之外鄉郊亦蘊含著城市人已久違了的純樸鄉情和傳統習俗，當中包含著許多人和人、人和地、地和天、天和人的纏綿交織。這些兜兜轉轉週而復始，卻又百聽不膩的故事與傳說，其實都和「回」字有著密不可分的關係。香港國際攝影節挑選昔日及現代的照片，呈現回的無數可能 － 回家、回鄉、回望、回春、回應、回報、回憶、回歸、回饋、回味、回敬、回祿、回眸、回流、回⋯⋯，還有回甚麼？ 就讓我們去谷埔走一回吧！',
        簡:'我们正处身一个资讯发达、物质丰盛的世代，但每当我们想安静下来的时候，乡郊仍然是我们非常向往的地方。乡郊和大自然二而为一，这或许就是人们喜欢乡郊的原因，但除此之外乡郊亦蕴含着城市人已久违了的纯朴乡情和传统习俗，当中包含着许多人和人、人和地、地和天、天和人的缠绵交织。这些兜兜转转周而复始，却又百听不腻的故事与传说，其实都和「回」字有着密不可分的关系。香港国际摄影节挑选昔日及现代的照片，呈现回的无数可能 － 回家、回乡、回望、回春、回应、回报、回忆、回归、回馈、回味、回敬、回禄、回眸、回流、回⋯⋯，还有回什么？ 就让我们去谷埔走一回吧！',
    },
    post1Date1:{
        EN:'18/1(Sat) - 16/2 (Sun)',
        繁:'18/1(六)至16/2(日)',
        簡:'18/1(六)至16/2(日)',
    },
    post1Time1:{
        EN:'12:00 noon – 7:00 pm',
        繁:'中午12:00 – 下午7:00',
        簡:'中午12:00 – 下午7:00',
    },
    post1Loca1:{
        EN:'Along the Dam',
        繁:'谷埔堤壩',
        簡:'谷埔堤坝',
    },
    post1Btn:{
        EN:'Electronic pamphlet',
        繁:'電子小冊子',
        簡:'电子小册子',
    },
    // post2
    post2Title:{
        EN:'Changlin Fashi',
        繁:'常霖法師',
        簡:'常霖法师',
    },
    post2Text:{
        EN:'Changlin Fashi, formerly known as Alain Yip, is a renowned photographer with nearly 60 years of experience in Hong Kong who graduated from The Hong Kong Polytechnic University in Design. In 2009, Changlin Fashi decided to give up his fame and fortune and went to Master Guo Ru’s temple in Taiwan to start his monastic life. Since then, he has led meditation retreats for public participation in a simple and easy-to-follow way. His works have been widely featured in various media and exhibitions. He has also published a range of complimentary publications on photography and meditation in daily life to share his insights into photography and practice experience.',
        繁:'常霖法師俗名葉青霖，為心呼吸創辦人及董事會主席，出家前為香港著名專業攝影師，於香港理工大學設計系畢業。\r\n\r\n接觸攝影經驗接近60年，在2009年放下俗世的名與利，到台灣於果如法師座下剃度出家。其作品常見於各類傳媒及展覽，亦經常出版免費結緣的攝影及生活禪修專集，分享他的攝影和修行心得，並經常以輕鬆易懂，融合生活藝術的方式帶領禪修活動，希望幫助多些人活出自在的人生。',
        簡:'常霖法师俗名叶青霖，为心呼吸创办人及董事会主席，出家前为香港着名专业摄影师，于香港理工大学设计系毕业。\r\n\r\n接触摄影经验接近60年，在2009年放下俗世的名与利，到台湾于果如法师座下剃度出家。其作品常见于各类传媒及展览，亦经常出版免费结缘的摄影及生活禅修专集，分享他的摄影和修行心得，并经常以轻松易懂，融合生活艺术的方式带领禅修活动，希望帮助多些人活出自在的人生。',
    },
    // post3
    post3Title:{
        EN:'Kelvin Yuen',
        繁:'袁斯樂',
        簡:'袁斯乐',
    },
    post3Text:{
        EN:[`Kelvin Yuen, a landscape photographer from Hong Kong, is the first Chinese individual to win The International Landscape Photographer of the Year Award, and The EPSON International Pano Award. He is the only Chinese photographer to have won the 'Photographer of the Year' awards of these prestigious international competitions.`,
            `Kelvin's works capturing the natural landscapes of Hong Kong have been featured on a stamp set titled "Hong Kong Natural Landscapes - Mountains", making him the first photographer in the region to have a complete set of stamps issued in his name. He is an official collaborating photographer for the Hong Kong Geopark and has previously held the position of photography consultant for the Hong Kong Tourism Board.`,
            'Moreover, Yuen has collaborated with several major global brands, including Cathay Pacific, HSBC, etc., on various projects.'],
        繁:[
            '香港風景攝影師袁斯樂，為首位華人奪得《ILPOTY 年度國際風景攝影師大賽》及《EPSON 國際全景攝影大賽》 總冠軍，並為唯一一位華人同時擁有上述兩項國際大賽的「年度攝影師」最高榮譽。\r\n\r\n袁氏的香港山野作品也獲香港郵政發行「香港自然景觀——群山」特別郵票，成為本港首名獲發行全套郵票的攝影師。現時為香港地質公園官方合作攝影師及曾任香港旅發局攝影顧問，他亦跟不同國際品牌如國泰航空、滙豐銀行等合作。'
        ],
        簡:[
            '香港风景摄影师袁斯乐，为首位华人夺得《ILPOTY 年度国际风景摄影师大赛》及《EPSON 国际全景摄影大赛》 总冠军，并为唯一一位华人同时拥有上述两项国际大赛的「年度摄影师」最高荣誉。\r\n\r\n袁氏的香港山野作品也获香港邮政发行「香港自然景观——群山」特别邮票，成为本港首名获发行全套邮票的摄影师。现时为香港地质公园官方合作摄影师及曾任香港旅发局摄影顾问，他亦跟不同国际品牌如国泰航空、滙丰银行等合作。'
        ],
    },
    // post4
    post4Title:{
        EN:'Alfred Ko',
        繁:'高志強',
        簡:'高志强',
    },
    post4Text:{
        EN:'Born in Hong Kong and studied photography at the Banff School of Fine Arts in Canada.  In the late 1980s, he co-founded the FOTOCINE School of Photography and the Photo Centre with friends. He has received multiple photography awards, including the "Photographer of the Year" by the Hong Kong Artists’ Guild in 1992. Ko is a founding member and honorable member of the Hong Kong Institute of Professional Photographers (HKIPP). He is also a founding member, former chairman of Hong Kong International Photo Festival (HKIPF) from 2013 to 2017 and is now the honorary chairman.',
        繁:'香港出生，於加拿大班夫藝術學院學習攝影。80年代末，與友人等創立影藝攝影學校及攝影中心。\r\n\r\n曾獲多個攝影奬項包括1992年香港藝術家聯盟頒發的「攝影家年奬」。高氏為香港專業攝影師公會創會會員，曾任兩屆主席，現為該會榮譽會員。 此外，他於2013至2017年期間曾擔任香港國際攝影節主席，現為榮譽主席。',
        簡:'香港出生，于加拿大班夫艺术学院学习摄影。80年代末，与友人等创立影艺摄影学校及摄影中心。\r\n\r\n曾获多个摄影奬项包括1992年香港艺术家联盟颁发的「摄影家年奬」。高氏为香港专业摄影师公会创会会员，曾任两届主席，现为该会荣誉会员。 此外，他于2013至2017年期间曾担任香港国际摄影节主席，现为荣誉主席。',
    },
    // post5
    post5Title:{
        EN:'Man Lim-chung',
        繁:'文念中',
        簡:'文念中',
    },
    post5Text:{
        EN:'Man Lim-chung has been active in the film industry and film awards since the 1990s, after graduating from The Hong Kong Polytechnic University, School of Design. In 1999, he won his first Hong Kong Film Award for Best Art Direction for the film "Tempting Heart".\r\n\r\nFurthermore, Man has also been involved in a wide range of artistic work in the entertainment industry in China and Taiwan, including but not limited to album covers, styling for concerts, art direction for performing arts, commercials, and spatial design. His 2020 documentary, "Keep Rolling," about director Ann Hui, marks his debut as a film director and has been featured in many overseas film festivals, including being invited as the opening film of the 44th Hong Kong International Film Festival and the opening film of the 16th Osaka Asian Film Festival.\r\n\r\nIn 2021, he held his first photography exhibition, "Side。晒". In 2023, he served as co-curator of the "Out of Thin Air: Hong Kong Film Arts & Costumes Exhibition", organized by the Hong Kong Heritage Museum. From 2021 to 2025, he is the Chairman of the Hong Kong Film Arts Association. ',
        繁:'1991年畢業於香港理工大學後加入電影美術設計，多次獲提名香港電影金像獎及台灣金馬獎最佳美術指導及最佳服裝造型設計。1999年憑《心動》首奪香港電影金像獎最佳美術指導。 \r\n\r\n他還負責中國大陸和台灣的多元化創作，包括專輯封面、演唱會造型、藝術指導、廣告和空間設計等。2020年首次執導許鞍華生平紀錄片《好好拍電影》，多次參加海外影展並獲邀為第44屆香港國際電影節及第16屆大阪亞洲電影節開幕電影。 \r\n\r\n2021年首辦個人攝影展《Side。哂》。2023 年任香港文化博物館「無中生有——香港電影美術及服裝造型展」聯合策展人。2021至2025年任香港電影美術學會會長。',
        簡:'1991年毕业于香港理工大学后加入电影美术设计，多次获提名香港电影金像奖及台湾金马奖最佳美术指导及最佳服装造型设计。1999年凭《心动》首夺香港电影金像奖最佳美术指导。 \r\n\r\n他还负责中国大陆和台湾的多元化创作，包括专辑封面、演唱会造型、艺术指导、广告和空间设计等。2020年首次执导许鞍华生平纪录片《好好拍电影》，多次参加海外影展并获邀为第44届香港国际电影节及第16届大坂亚洲电影节开幕电影。 \r\n\r\n2021年首办个人摄影展《Side。哂》。2023 年任香港文化博物馆「无中生有——香港电影美术及服装造型展」联合策展人。2021至2025年任香港电影美术学会会长。',
    },
}