import '../App.css';
import { about } from '../content/carpark';
import { homeContent } from '../content/home';
import Navbar from '../conponents/Navbar'
//
import LogoSvg from '../svgs/LogoSvg'
import ShortDivider from '../conponents/ShortDivider'
import { useNavigate } from 'react-router-dom';
import { s3_PREFIX } from '../config';

function App({lang,handleSetRightNav}) {
    const ab = about
    const navigate = useNavigate();
    function navigating(str){ navigate(str) }
  return (
    <div className='relative flex flex-col w-full text-[black] h-full bg-[#D0D6D0]'>
        {/*header*/}
        {/* <div className='w-full bg-white flex flex-row justify-between items-center' style={{borderBottom:'18px solid #01A334', zIndex: 99}}>
            <Navbar lang={lang} top={true} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
        </div> */}
        <Navbar sticky={true} lang={lang} handleSetRightNav={(bol) => handleSetRightNav(bol)} noBorder={false}/>
        {/*header*/}
        
        <div className='w-full'>
            {/* <img className='w-full' src={s3_PREFIX+'/images/aboutbg.jpg'} /> */}

            <div className='bg-[#D0D6D0] flex flex-col items-center my-[48px] text-[#000000]'>
                <div className='flex flex-col w-full p-page items-center'>
                    <div className='home-title text border text-[#ffffff] border-[#ffffff] border-[4px] rounded-full w-full max-w-[850px] bg-[#01A334]'>{ab.title[lang]}</div>
                    <ShortDivider width={'100%'} maxW={350} py={4} color={'#000000'}/>
                    <div className='relative w-full flex row-col start-to-center justify-center p-concert' style={{gap:'58px'}}>
                        <div className='flex flex-1'>
                            <img src={'/images/home/carmap.png'} className='w-full rounded-xl max-h-[630px]' style={{aspectRatio: 596/630, objectFit:'cover'}}></img>
                        </div>
                        <div className='flex flex-1 flex-col justify-between text-[#000000]'>
                            {ab.text[lang].map((word) => <div className='home-22-text text-justify mb-[6px] text-[#000000]'>{word}</div>)}
                            {lang === 'EN' &&<div className='home-22-text text-left mt-[18px]'><a className='underline font-bold' target='_blink' href='https://docs.google.com/forms/d/e/1FAIpQLSfKu2XLhktpHcnJDLjmld9pfbtLf5nEJFW5DCI4Sr9D7Z-eyg/viewform'>Parking reservation and details</a></div>}
                            {lang === '繁' && <div className='home-22-text text-left mt-[18px]'><a className='underline font-bold' target='_blink' href='https://docs.google.com/forms/d/e/1FAIpQLSfKu2XLhktpHcnJDLjmld9pfbtLf5nEJFW5DCI4Sr9D7Z-eyg/viewform'>車位預約及詳情</a></div>}
                            {lang === '簡' &&<div className='home-22-text text-left mt-[18px]'><a className='underline font-bold' target='_blink' href='https://docs.google.com/forms/d/e/1FAIpQLSfKu2XLhktpHcnJDLjmld9pfbtLf5nEJFW5DCI4Sr9D7Z-eyg/viewform'>车位预约及详情</a></div>}
                            {/* <div className='home-22-text text-left mt-[6px]'>{ab.whatsapp[lang]}<span className='underline'>+852 9088 4428</span></div> */}
                        </div>
                    </div>
                </div>
                {/* <div className='relative w-full bg-[#01A334] mt-[128px]'>
                    <img className='w-full' src={s3_PREFIX+'/images/home/stack1.png'}></img>
                    <img className='w-full absolute top-0 left-0' src={s3_PREFIX+'/images/home/stack2.png'}></img>
                    <img className='w-full absolute top-0 left-0' src={s3_PREFIX+'/images/home/stack3.png'}></img>
                </div> */}
            </div>
        </div>
    </div>
  );
}

export default App;
