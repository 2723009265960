import Calander from '../svgs/Calander'
import Clock from '../svgs/Clock'
import Loca from '../svgs/Loca'

export function Component({date, time, loca1, loca2, style}){
    return <div className='w-full flex flex-col mt-[13px]' style={{gap: 14, ...style}}>
    <div className='flex flex-row items-center'>
        <Calander id={time} style={{marginRight: 12,minWidth:50}}/>
        <div className='home-22-text text-left text-wrap'>{date}</div>
    </div>
    <div className='flex flex-row items-center'>
        <Clock id={time} style={{marginRight: 12,minWidth:50}}/>
        <div className='home-22-text text-left text-wrap' style={{whiteSpace:'pre-wrap'}}>{time}</div>
    </div>
    <div className='flex flex-row items-center'>
        <div className='self-start'>
            <Loca id={time} style={{marginRight: 12,minWidth:50}}/>
        </div>
        <div>
            <div className='home-22-text text-left text-wrap'>{loca1}</div>
            {loca2 && <div className='ml-[20px] home-22-text text-left'>{loca2}</div>}
        </div>
    </div>
</div>
}

export default Component;
