export const about = {
    carTitle:{
        EN:'Sha Tau Kok Visitor Car Park',
        繁:'沙頭角旅客停車場',
        簡:'沙头角旅客停车场',
        
    },
    whatsapp:{
        EN:'WhatsApp hotline:',
        繁:'WhatsApp查詢專線：',
        簡:'WhatsApp查询专线：',
        
    },
    carText:{
        EN:[
            'Located on Sha Ho Road in Sha Tau Kok, adjacent to the Sha Tau Kok Frontier Closed Area entrance.',
            '100 parking space for private cars for individual visitors* upon reservation.',
            `*Visitors should obtain a valid ”Tourism Closed Area Permit (CAP)”`,
        ],
        繁:[
            '位於沙頭角沙河路、毗鄰沙頭角禁區入口提供100個私家車位給予個人旅客預約停泊*。','*使用者須持有「旅遊禁區許可證」'
        ],
        簡:[
            '位于沙头角沙河路、毗邻沙头角禁区入口提供100个私家车位给予个人旅客预约停泊*。','*用户须持有「旅游禁区许可证」'
        ],
    }
}